import React from 'react'
import StoreView from './stores-view'

function StoreController() {
  // TODO: load store detail

  return (
    <StoreView />
  )
}

export default StoreController
