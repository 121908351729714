import React, { useEffect } from 'react'
import { useLink } from '../../hook'

function StoresView() {
  const { navigate } = useLink()

  useEffect(() => {
    navigate('/store-locations/', { replace: true })
  }, [])

  return <div />
}

export default StoresView
